<template>
    <section>
        <div class="row mx-0">
            <div class="detalle col-xl-7 col-lg px-0 alto overflow-auto custom-scroll border-right">
                <div v-if="id_promocion" class="row mx-0 px-3 my-1 border-bottom border-right align-items-center" style="height:40px;">
                    <div class="col-auto px-1 text-general">
                        <p class=""> Detalle de la promoción</p>
                    </div>
                    <p class="col tres-puntos text-right">
                        <i class="icon-calendar-clock mr-1 text-gr-red f-20" />
                        {{ formatearFecha(promocion.inicio, 'DD MMM') }} -
                        {{ formatearFecha(promocion.fin, 'DD MMM') }}
                    </p>
                </div>
                <div class="detalle-content p-3 f-15 overflow-auto custom-scroll" style="height: calc(100% - 55px);">
                    <content-loader v-if="loading || promociones.length===0" />
                    <sinDatos v-if="detalle.id === null" icon="sale" mensaje="Seleccione una promoción." />
                    <div v-else>
                        <div class="row mx-0">
                            <img class="obj-cover br-10 w-100 br-12 shadow" height="160" :src="detalle.imagen_show" />
                        </div>
                        <div class="row mx-0 my-3 text-general text-center f-600 f-22">
                            {{ detalle.nombre }}
                        </div>
                        <div class="row mx-0 my-3 text-general" style="word-break:break-all">
                            <p class="f-600">Condiciones: </p>
                            <p> {{ detalle.condiciones }}</p>
                        </div>
                        <div class="row mx-0 my-3 text-general">
                            <span class="f-17 f-600">Tipo de promoción:</span>
                            <span class="my-auto ml-2">{{ detalle.tipoShow }}</span>
                        </div>
                        <div class="row mx-0 mt-2 text-general f-600 f-17">
                            Categorías (Productos añadidos por las tiendas):
                        </div>
                        <div class="row mx-0 my-2 f-17">
                            <div v-for="(info, idx) in detalle.promo_categorias" :key="idx" class="bg-whitesmoke br-20 px-3 border py-1 m-2">
                                {{ info.nombre }}({{ info.cant_productos }})
                            </div>
                        </div>
                        <div class="row mx-0 my-2 text-general pl-2">
                            Previsualización ejemplo
                        </div>
                        <div class="row mx-0 my-2">
                            <cardProductoVer :info="detalle" class="p-2 mx-2 f-15 overflow-auto custom-scroll" style="max-height: calc(100% - 80px);" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="productos col-xl-5 col-lg px-0 alto overflow-auto custom-scroll">
                <div v-if="id_promocion" class="row mx-0 align-items-center border-bottom my-1 px-2 " style="height:40px;">
                    <span> Productos de la promoción </span>
                    <div class="br-10 text-white px-2 f-17 ml-auto bg-general">
                        <i class="icon-package-variant-closed f-18 mr-2" />
                        <span>{{ promocion.pro_agregados }}</span>
                    </div>
                </div>

                <div class="productos-content p-2 mx-2 f-15 overflow-auto custom-scroll" style="max-height: calc(100% - 80px);">
                    <div v-for="(item, idx) in productos" :key="idx" class="row mx-0">
                        <cardProductoPromocion
                        :info="item"
                        :tendero="true"
                        class="p-2 mx-2 f-15 overflow-auto custom-scroll"
                        style="max-height: calc(100% - 80px);"
                        />
                    </div>
                </div>
                <content-loader v-if="loading || promociones.length===0" />
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Promocion from '~/services/promociones/promociones_tendero'

export default {
    name: 'DetallePromocion',
    data(){
        return {
            buscar: '',
            loading: false
        }
    },
    computed:{
        ...mapGetters({
            detalle: 'promociones/promociones_tendero/detalle',
            id_promocion: 'promociones/promociones_tendero/id_promocion',
            promociones: 'promociones/promociones_tendero/promociones',
            promocion: 'promociones/promociones_tendero/promocion',
            productos: 'promociones/promociones_tendero/productos',
        }),

    },
    methods: {

        async actualizarDetalle(){
            const enviar = {
                id_promocion: this.detalle.id,
                id_tienda: null
            }
            this.loading = true
            await this.$store.dispatch('promociones/promociones_tendero/action_listar_detalle_promocion',enviar )
            this.loading = false

        },
        limpiarPromocion(){
            const datos ={
                id: null,
                nombre: '',
                imagen: null,
                imagen_show: null,
                tipo: '',
                condiciones: '',
                categorias:1,
                visible: 1,
                promo_categorias:[]
            }
            this.$store.commit('promociones/promociones_tendero/set_detalle', datos)

        }
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 97px);
}

@media only screen and (min-width : 768px) and (max-width : 1024px) {
    .detalle-content{
        height: auto !important;
    }    
    .detalle.alto{
        height: auto!important;
    }
    .productos{
        height: auto !important;
        .productos-content{
            height: auto !important;
            max-height: none !important;
        }
    } 
    
}
@media only screen and (max-width : 768px){
    .detalle-content{
        height: auto !important;
    }    
    .detalle.alto{
        height: auto!important;
    }
    .productos{
        height: auto !important;
        .productos-content{
            height: auto !important;
            max-height: none !important;
        }
    }
}
</style>
